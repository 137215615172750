import { NgModule } from '@angular/core';
import { BootstrapComponent } from './bootstrap.component';
import { BootstrapRoutingModule } from './bootstrap-routing.module';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import {
  faFacebookF,
  faLinkedinIn,
  faTwitter
} from '@fortawesome/free-brands-svg-icons';
import { faCheck, faEnvelope, faLink } from '@fortawesome/free-solid-svg-icons';

@NgModule({
  declarations: [BootstrapComponent],
  imports: [
    RouterModule,
    BootstrapRoutingModule,
    BrowserModule,
    BrowserModule.withServerTransition({ appId: 'pitch59' }),
    HttpClientModule,
    BrowserAnimationsModule
  ],
  bootstrap: [BootstrapComponent]
})
export class BootstrapModule {
  constructor(private library: FaIconLibrary) {
    this.library.addIcons(faFacebookF);
    this.library.addIcons(faTwitter);
    this.library.addIcons(faLinkedinIn);
    this.library.addIcons(faEnvelope);
    this.library.addIcons(faLink);
    this.library.addIcons(faCheck);
  }
}
